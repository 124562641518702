import React, { useState, useEffect } from "react";
import { renderIcon } from '../../utils/ui.js';

import { connect } from "react-redux";
import HeaderContent from '../common/Header/HeaderContent';
import CustomDataBreadTable from "./CustomDataBreadTable"
import './customDataset.css'
import { Button, Tooltip, Typography } from '@material-ui/core';
import moment from 'moment';
import { viewCheckoutHistory,fetchCustomCheckoutHistory } from '../../actions/retrieveDataActions';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from "@material-ui/core/styles";

const queryMigrationServiceStyle = makeStyles({
  CircularProgressStyle: {
      width: "70px !important",
      height: "70px !important",
      marginLeft: "600px",
      marginBottom: "100px",
      marginTop: "40px"
  }
});

const CustomCheckoutHistory = (props) => {
  const classes = queryMigrationServiceStyle();
  useEffect(() => {
    const req = { "environment": props.env,
    "userId": props.username, "datasetType": "BYOD"}
    props.fetchCustomCheckoutHistory(req).then((res) => {
      if(res)
      {
        setCheckedOutData([...res.data])
        setInitialCheckedOutData([...res.data])
      }
    });
  }, []);


  const [openFilter, setOpenFilter] = useState(false);
  const [checkedOutData, setCheckedOutData] = useState([]);
  const [initialCheckedOutData, setInitialCheckedOutData] = useState([]);
  const [searchText, setSearchText] = useState(["","","","","",""]);

  const dataArgs = {
    size: "large",
    isLoading: false,
    isExpandable: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, { label: 'All', value: -1 }],
    columnMetadata: [
      {
        dataset: 1,
        title: "Checkout Id",
        field: ["checkoutId"],
        expandable: true,
        component: (field) => <Tooltip title={field}><Typography noWrap style={{ "width": "135px", "margin": "auto" }} noWrap variant="body2">
          {field}
        </Typography></Tooltip>,
      },
      {
        dataset: 2,
        title: "Name",
        field: ["datasetName"],
        expandable: true,
        component: (field) => <Tooltip title={field}><Typography noWrap style={{ "width": "135px", "margin": "auto" }} noWrap variant="body2">
          {field}
        </Typography></Tooltip>,
      },
      {
        dataset: 4,
        title: "Scope",
        field: ["scope"],
        expandable: true,
        component: (field) => <div class="ml-1 d-inline-block" >
          <Typography style={{ "width": "135px", "margin": "auto", "display": "inline-block" }} noWrap variant="body2">
            {field}
            {field === "TEAM" ?
              <i class="fa fa-users teamIcon ml-2 d-inline-block"></i> : <>
                {field === "PRIVATE" ? <i class="fa fa-user userIcon ml-2 d-inline"></i> :
                  <i class="ml-2 d-inline"></i>}
              </>
            }
          </Typography>

        </div>,
      },
      {
        dataset: 4,
        title: "Owner",
        field: ["checkedOutBy"],
        expandable: true,
        component: (field) => <Tooltip title={field}><Typography noWrap style={{ "width": "120px", "margin": "auto" }} noWrap variant="body2">
          {field}
        </Typography></Tooltip>,
      },
      {
        dataset: 4,
        title: "Quantity",
        field: ["quantity"],
        expandable: true,
        component: (field) => <td style={{ textAlign: 'center' }}>
          {(!!field || field === 0) && <span className="team-circle" id="team-qty-circle">{field}</span>}
        </td>,
      },
      {
        dataset: 4,
        title: "Time",
        field: ["checkoutTime"],
        expandable: true,
        component: (field) => <td className="base-turquoise" style={{ "min-width": "135px", "margin": "auto" }}>
          {renderIcon("icon-time")} {moment(field).format("DD-MM-YYYY")}</td>
      },
      {
        dataset: 4,
        title: "View",
        field: ["checkoutId"],
        expandable: true,
        component: (field) => <Button variant="contained" color="primary"
          onClick={e => viewCheckoutHistory(field)}>
          View
        </Button>
      }
    ],

  }




  const viewCheckoutHistory = (row) => {
    let checkedOut = checkedOutData.filter((data) => data.checkoutId === row);
    let checkedOutDataSummary = {
      "checkedDataSummary": {
        "name": checkedOut[0].datasetName,
        "scope": checkedOut[0].scope,
        "userId": checkedOut[0].checkedOutBy,
        "checkedOutData": [...checkedOut[0].data]
      }
    }
    console.log("checkedOutDataSummary")
    console.log(checkedOutDataSummary)
    props.viewCheckoutHistory(checkedOutDataSummary).then((response) => {
      if (response) {
        props.history.push('/dataset/checkout');
      }
    })
  }
  const handleOpenFilter = () => {
    setOpenFilter(!openFilter)
  }
  const handlefilterData = (colHeader, filteredDataArray) => {
    let filteredData = [...checkedOutData];
    let searchTextList = [...searchText]
    for (var i = 0; i < filteredDataArray.length; i++)
    {
      if (filteredDataArray[i].col === "OWNER")
      {
        if (filteredDataArray[i].value !== undefined)
        {

          filteredData = filteredData.filter((data) => (((data.checkedOutBy).toString()).toLowerCase()).includes(((filteredDataArray[i].value).toString()).toLowerCase()))
          searchTextList[i] = filteredDataArray[i].value;
          setSearchText(searchTextList)
        }
        else
        {
          filteredData = initialCheckedOutData
          searchTextList[i] = "";
          setSearchText(searchTextList)
        }
      }
      if (filteredDataArray[i].col === "Checkout Id")
      {
        if (filteredDataArray[i].value !== undefined)
        {
          filteredData = filteredData.filter((data) => (((data.checkoutId).toString()).toLowerCase()).includes(((filteredDataArray[i].value).toString()).toLowerCase()))
          searchTextList[i] = filteredDataArray[i].value;
          setSearchText(searchTextList)
        }
        else
        {
          filteredData = initialCheckedOutData
          searchTextList[i] = "";
          setSearchText(searchTextList)
        }
      }
      if (filteredDataArray[i].col === "Name")
      {
        if (filteredDataArray[i].value !== undefined)
        {
          filteredData = filteredData.filter((data) => (((data.datasetName).toString()).toLowerCase()).includes(((filteredDataArray[i].value).toString()).toLowerCase()))
          searchTextList[i] = filteredDataArray[i].value;
          setSearchText(searchTextList)
        }
        else
        {
          filteredData = initialCheckedOutData
          searchTextList[i] = "";
          setSearchText(searchTextList)
        }
      }
      if(filteredDataArray[i].col === "Scope")
      {
        if(filteredDataArray[i].value !== undefined)
        {
          filteredData = filteredData.filter((data) => (((data.scope).toString()).toLowerCase()).includes(((filteredDataArray[i].value).toString()).toLowerCase()))
          searchTextList[i] = filteredDataArray[i].value;
          setSearchText(searchTextList)
        }
        else
        {
          filteredData = initialCheckedOutData
          searchTextList[i] = "";
          setSearchText(searchTextList)
        }
      }
     
      if (filteredDataArray[i].col === "Quantity")
      {
        if (filteredDataArray[i].value !== undefined)
        {
          filteredData = filteredData.filter((data) => ((data.quantity).toString()).includes((parseInt(filteredDataArray[i].value)).toString()))
          searchTextList[i] = filteredDataArray[i].value;
          setSearchText(searchTextList)
        }
        else
        {
          filteredData = initialCheckedOutData;
          searchTextList[i] = "";
          setSearchText(searchTextList)
        }
      }
    
    }
    setCheckedOutData(filteredData)
  }
 
  return (
    <div className="white" id="">
      <HeaderContent
        title={`Custom Checkout History`}
        icon="history training-icon "
        empty={true}
        loadingText="Loading checkout history..."></HeaderContent>
{checkedOutData.length > 0 ? <CustomDataBreadTable
        {...dataArgs} data={checkedOutData} filterHeader={true}
        openFilter={openFilter} handleOpenFilter={handleOpenFilter}
        handlefilterData={handlefilterData}
        filterClass="filterAutoCompleteText" 
        filterClassEmpty="filterClassEmpty"/> : <CircularProgress classes={{ root: classes.CircularProgressStyle }} />}
      </div>
  )
}
const mapStateToProps = (state) => {
  return {
    username: state.auth.user.UserName,
    env : state.auth.selectedEnvValue
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    viewCheckoutHistory: (row) => dispatch(viewCheckoutHistory(row)),
    fetchCustomCheckoutHistory: (req) => dispatch(fetchCustomCheckoutHistory(req))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomCheckoutHistory);